@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;700&family=Roboto:wght@300;400;500;600&display=swap');

:root {
   --brand-color: #47529E;
   --light-brand-color: #767eb3;
   --brand-grey: #58595B;
   --brand-ivory: #E5DDBD;
}

.App {
   font-family: var(--main-font);
   color: var(--dark);
}

a {
   text-decoration: none;
   color: var(--dark);
}
@import url(https://fonts.googleapis.com/css2?family=Muli:wght@400;700&family=Roboto:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.FlashMessages .Toastify__toast {
  border-radius: 4px;
  font-size: 0.8em;
  min-height: 50px;
}
.FlashMessages .Toastify__toast--success {
  background: var(--success);
}
.FlashMessages .Toastify__toast--error {
  background: var(--danger);
}
.FlashMessages .Toastify__toast-body {
  padding: 0 15px;
  text-align: left;
}

.react-calendar__tile--active {
  background-color: var(--main-color) !important;
}
:root {
   --brand-color: #47529E;
   --light-brand-color: #767eb3;
   --brand-grey: #58595B;
   --brand-ivory: #E5DDBD;
}

.Theme_App__2VzmY {
   font-family: var(--main-font);
   color: var(--dark);
}

a {
   text-decoration: none;
   color: var(--dark);
}
.repairy_colours__2IsEA {
  --main-color: #278ea5;
  --light-main-color: #3290a5;
  --dark-main-color: #1a5e6d;
  --light: #fff;
  --dark: #3d3d3d;
  --danger: #f85959;
  --success: #098e49;
  --yellow: #f8cd59;
  --dark-yellow: #594100;
  --border-radius: 5px;
  --muli: 'Muli', sans-serif;
  --box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04),
    0 12px 48px 0 rgba(0, 0, 0, 0.08);
  --main-font: 'Roboto', sans-serif;
  --animation-speed: 750;
}

.tyreplus_colours__2ysq8 {
  --main-color: #009966;
  --light-main-color: #37a47f;
  --dark-main-color: #028b5d;
  --light: #fff;
  --dark: #3d3d3d;
  --danger: #f85959;
  --success: #098e49;
  --yellow: #f8cd59;
  --dark-yellow: #594100;
  --border-radius: 5px;
  --muli: 'Muli', sans-serif;
  --box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04),
    0 12px 48px 0 rgba(0, 0, 0, 0.08);
  --main-font: 'Roboto', sans-serif;
  --animation-speed: 750;
}


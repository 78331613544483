.colours {
  --main-color: #009966;
  --light-main-color: #37a47f;
  --dark-main-color: #028b5d;
  --light: #fff;
  --dark: #3d3d3d;
  --danger: #f85959;
  --success: #098e49;
  --yellow: #f8cd59;
  --dark-yellow: #594100;
  --border-radius: 5px;
  --muli: 'Muli', sans-serif;
  --box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04),
    0 12px 48px 0 rgba(0, 0, 0, 0.08);
  --main-font: 'Roboto', sans-serif;
  --animation-speed: 750;
}
